/* eslint-disable @typescript-eslint/no-explicit-any */
declare const scripts: any;
declare const hbspt: any;
/* eslint-enable @typescript-eslint/no-explicit-any */


export default function initHubspotMultistepForms() : void {
    const $containerForm = document.querySelectorAll<HTMLElement>('.js-hubspot-multistep-form');

    $containerForm.forEach($container => {
        new HubspotMultistepForm($container);
    });
}

/**
 * Gère plusieurs formulaires Hubspot qui
 * s'enchainent à la soumission du précédent
 * 
 * @param {HTMLElement}    $container  Hubspot forms Container
 * @param {integer}        portalId    Hubspot portal ID
 * @param {Array.<string>} formsId     Hubspot forms id
 * @param {Array}          data        Hubspot forms data
 * @param {Array}          options     Hubspot forms options
 */
class HubspotMultistepForm {
    $container: HTMLElement | null | undefined;
    portalId: string | null | undefined;
    formsId: string[] | null | undefined;
    redirection: string | null | undefined;
    data: [] | null | undefined;
    options: [] | null | undefined;

    constructor($container) {
        this.$container = $container;
        this.portalId = this.$container.dataset.portalId;
        this.formsId = this.$container.dataset.forms.split(',');
        this.redirection = this.$container.dataset.redirection;

        this.data = [];
        this.options = [];

        this.formsId.forEach((formId, step) => {
            this.options.push(this.generateFormOptions(formId, step));
        });

        hbspt.forms.create(this.options[0]);
    }

    /**
     * Création des options Hubspot
     * d'un formulaire à partir de son ID
     * 
     * @param {string}  formId Hubspot form ID
     * @param {integer} step   Step of the form
     * 
     * @return {void}
     */
    generateFormOptions(formId: string, step: number) {

        const $iframeContainer = document.querySelector('#' + this.$container.getAttribute('id'));
        
        return {
            portalId: this.portalId,
            formId,
            target: '#' + this.$container.getAttribute('id'),
            onFormReady: $form => {
                const $html = $form.closest('html');
                const linkTag = document.createElement('link');
                linkTag.rel = 'stylesheet';
                linkTag.href = scripts.css_url;
                let $head = null;

                if ($html && (typeof $html === 'object')) {
                    $head = $html[0] ? $html[0].querySelector('head') : $html.querySelector('head');
                }

                if ($head) {
                    $head.appendChild(linkTag);
                }
                
                setTimeout(function() {
                    $iframeContainer.querySelector('iframe').height = $html.scrollHeight;
                    $iframeContainer.querySelector('iframe').style.height = $html.scrollHeight+ 'px';
                }, 1500);
                
               
                if (step !== 0) {
                    $form.find('input[name="email"]').val(this.data[0].value).change();
                }
            },
            onFormSubmitted: () => {
                if (this.redirection) {
                    document.location.href=this.redirection;
                }
            },
        };
    }
}
