//import initModals from './components/modal';
//import initReadMore from './modules/read-more-block';
import initAppearAnim from './utils/appear-anim';
import initNav from './components/nav';
import initDropdownGrid from './utils/dropdown-grid';
import initDropdown from './utils/dropdown';
//import initInputCustom from './components/input-custom';
//import { initYoutubeIframe } from './components/youtube-iframe';
import initMaillagePostsSimilaires from './template-parts/maillage-posts-similaires';
import initSingleContentSummary from './template-parts/single-content-summary';
import initTypeContent from './blocks/type-content';
import initAccordeon from './blocks/accordeon';
//import initGridPostsSwiper from "./components/grid-posts";
import initCarouselVignettes from './blocks/carousel-vignettes';
import initCarouselImagesPleineLargeur from './blocks/carousel-citations';
import initTabs from './blocks/tabs';
import initTabsVertical from './blocks/tabs-vertical';
import initVideo from './blocks/video';
import initScrollLogos from './blocks/liste-logos';
import initDerniersPosts from './blocks/derniers-posts';
import initImplantationOnglets from './blocks/implantation-onglets';
import initHubspotMultistepForms from './components/hubspot-multistep-form';
import initDemandeDeDevis from './blocks/header-demande-de-devis';
import { initYoutubeIframe } from './components/youtube-iframe';
import initInputAutocomplete from './components/input-autocomplete';
import initSwitchContext from './components/switch-context';

// Fix de la valeur de "100vh" pour les appareils iOS + Fix de la valeur de "100vw"
function appHeight() {
    const vh = (window.innerHeight * 0.01) * 100;
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    const vw = document.body.clientWidth;
    document.documentElement.style.setProperty('--vw', `${vw}px`);
}
window.addEventListener('load', appHeight);
window.addEventListener('resize', appHeight);


// Utils
initAppearAnim();
initDropdownGrid();
initDropdown();

// Modules
//initReadMore();
initNav();

// Blocks
initTabsVertical();
initTabs();
initTypeContent();
initAccordeon();
initCarouselVignettes();
initCarouselImagesPleineLargeur();
initScrollLogos();
initDerniersPosts();
initImplantationOnglets();
initDemandeDeDevis();

// Blocks Video 
initYoutubeIframe();
initVideo();

//TEMPLATE PARTS
initMaillagePostsSimilaires();
initSingleContentSummary();


// Components (En dernier sinon les bouton modals dans un swiper ne s'initialise pas)
//initInputCustom();
//initModals();
//initGridPostsSwiper();
initHubspotMultistepForms();
initInputAutocomplete();
initSwitchContext();



