export default function initSwitchContext() : void {
    const $switchContext = document.querySelectorAll<HTMLElement>('.js-switch-context');

    $switchContext.forEach($container => {
        new SwitchContext($container);
    });
}

class SwitchContext {
    $el: HTMLElement | null | undefined;
    $switchLinks: NodeListOf<HTMLElement> | null | undefined;
    $indicator: HTMLElement | null | undefined;


    constructor($el: HTMLElement | null) {
        this.$el = $el;
        this.$switchLinks = $el.querySelectorAll('.switch-link');
        this.$indicator = $el.querySelector('.switch-context-active-indicator');

        this.init();
    }

    /**
     * Init switch context
     */
    init(): void {
        this.updateTabIndicatorPosition();
        this.$switchLinks?.forEach((button) => {
            button.addEventListener('click', () => {
                this.$switchLinks?.forEach((button) => {
                    button.classList.remove('switch-link--active');
                });
                button.classList.add('switch-link--active');
                this.updateTabIndicatorPosition();
            });
        });
    }

    /**
     * Update indicator position desktop
     */
    updateTabIndicatorPosition(): void {
        if (this.$indicator) {
            const activeButton = this.$el?.querySelector('.switch-link--active');
            const buttonRect = activeButton.getBoundingClientRect();
            const containerRect = this.$el?.getBoundingClientRect();
        
            if (containerRect && buttonRect) {
                const offsetLeft = buttonRect.left - containerRect.left - 5;
                const offsetWidth = buttonRect.width;
        
                this.$indicator.style.width = `${offsetWidth}px`;
                this.$indicator.style.transform = `translateX(${offsetLeft}px)`;
            }

            if (!this.$indicator.classList.contains('switch-context-active-indicator-ready')) {
                setTimeout(() => {
                    this.$indicator.classList.add('switch-context-active-indicator-ready')
                    activeButton.classList.remove('switch-link--active-ready')
                }, 400);
            }
            
        }
    }
}
