import Swiper from 'swiper';
import { Pagination } from 'swiper/modules';


export default function initDerniersPosts() : void {
    const $derniersPostsContainer = document.querySelectorAll<HTMLElement>('.block-derniers-posts');

    $derniersPostsContainer.forEach($container => {
        new DerniersPosts($container);
    });
}

class DerniersPosts {
    $el: HTMLElement | null | undefined;
    $slider: HTMLElement | null | undefined;
    swiper: Swiper | null = null;
    $pagination: HTMLElement | null | undefined;
    mdBreakpoint: number;

    constructor($el: HTMLElement | null) {
        this.$el = $el;
        this.$slider = $el?.querySelector('.wrapper-articles');
        this.$pagination = $el?.querySelector('.derniers-posts-pagination');
        this.mdBreakpoint = parseInt(getComputedStyle(document.documentElement).getPropertyValue('--breakpoint-md'));

        window.addEventListener('resize', this.onWindowResize.bind(this));
        this.onWindowResize();
    }

    /**
     * Événement lancé à chaque resize de la fenêtre
     * Détermine s'il faut créer ou détruire le swiper
     */
    onWindowResize(): void {
        if (this.swiper && window.innerWidth > this.mdBreakpoint) {
            // Détruit le swiper s'il est déjà créé et qu'on est au dessus du breakpoint md
            this.destroySwiper();
        } else if (!this.swiper && window.innerWidth <= this.mdBreakpoint) {
            // Créé le swiper s'il n'est pas déjà créé et q'uon est au dessous du breakpoint md
            this.createSwiper();
        }
    }

    /**
     * Créé le swiper
     */
    createSwiper(): void {
        if (this.$slider) {
            this.swiper = new Swiper(this.$slider, {
                modules: [Pagination],
                slidesPerView: 'auto',
                spaceBetween: 30,
                loop: false,
                allowTouchMove: true,
                grabCursor: true,
                pagination: {
                    el: this.$pagination,
                    clickable: true
                },
            });
        }
    }

    /**
     * Détruit le swiper
     */
    destroySwiper(): void {
        this.swiper?.destroy(true, true);
        this.swiper = null;
    }
}
