
export default function initTabs() {
    const $blocksTabs = document.querySelectorAll<HTMLElement>('.block-tabs');
    $blocksTabs.forEach($container => {
        new BlockTabs($container);
    });
}

/**
 * @param {HTMLElement} $el           HTML Element Block nos offres
 */
class BlockTabs {
    $el: HTMLElement | null | undefined;
    $tabs: NodeListOf<HTMLElement> | null | undefined;
    $tabsButtons: NodeListOf<HTMLElement> | null | undefined;
    mdBreakpoint: number;
    viewDevice: string;
    tabsWrapperDesktop : HTMLElement | null | undefined;
    tabsSelectMobile : HTMLSelectElement | null | undefined;
    tabButtonActive : HTMLElement | null | undefined;
    $tabIndicator: HTMLElement | null | undefined;
    
    constructor($el: HTMLElement | null) {
        this.$el = $el;
        this.$tabs = $el?.querySelectorAll('[data-tab]');
        this.$tabsButtons = $el?.querySelectorAll('[data-tab-target]');
        this.mdBreakpoint = parseInt(getComputedStyle(document.documentElement).getPropertyValue('--breakpoint-md'));
        this.tabsWrapperDesktop = $el?.querySelector('.js-tabs-title');
        this.tabsSelectMobile = $el?.querySelector('.js-tabs-title-mobile');
        this.tabButtonActive = $el?.querySelector('[data-tab-target].active');
        this.$tabIndicator = $el?.querySelector('.tab-title-active-indicator');
        
        this.viewDevice = '';

        window.addEventListener('resize', this.onWindowResize.bind(this));
        this.onWindowResize();
        this.TabHandler();
    }

    onWindowResize() : void {
        if (this.viewDevice != 'desktop' && window.innerWidth > this.mdBreakpoint) {
            this.viewDevice = 'desktop';
            this.updateTabIndicatorPosition();
        
        } else if (this.viewDevice != 'mobile' && window.innerWidth <= this.mdBreakpoint) {
            this.viewDevice = 'mobile';
        }
    }

    /**
     * Initialise les onglets en desktop
     */
    TabHandler() : void {
        this.$tabsButtons?.forEach((button) => {
            button.addEventListener('click', this.buttonTabClick.bind(this, button));
        })

        this.tabsSelectMobile.addEventListener('change', this.selectTabChange.bind(this));

    }

    /**
     * Action au click d'un onglet desktop
     */
    buttonTabClick(button) : void {
        const activeButtonDataTarget = button.getAttribute('data-tab-target');
        if (!button.classList.contains('active')) {
            this.$tabsButtons?.forEach(button => button.classList.remove('active'))
            button.classList.add('active');
        }

        this.$tabs?.forEach((tab) => {
            if(activeButtonDataTarget === tab.getAttribute('data-tab')) {
                this.$tabs?.forEach(tab => tab.classList.remove('active'));
                tab.classList.add('active');
            }
        })

        this.tabsSelectMobile.value = activeButtonDataTarget;
        this.updateTabIndicatorPosition();
    }

    /**
     * Action au click d'un onglet mobile
     */
    selectTabChange() : void {
        const activeButtonDataTarget = this.tabsSelectMobile.value;

        this.$tabsButtons?.forEach((button) => {
            if(activeButtonDataTarget === button.getAttribute('data-tab-target')) {
                this.$tabsButtons?.forEach(tabButton => tabButton.classList.remove('active'));
                button.classList.add('active');
            }
        })

        this.$tabs?.forEach((tab) => {
            if(activeButtonDataTarget === tab.getAttribute('data-tab')) {
                this.$tabs?.forEach(tab => tab.classList.remove('active'));
                tab.classList.add('active');
            }
        })
    }

    /**
     * Update indicator position desktop
     */
    updateTabIndicatorPosition(): void {
        if (this.$tabIndicator) {
            const activeButton = this.$el?.querySelector('[data-tab-target].active');
            const buttonRect = activeButton.getBoundingClientRect();
            const containerRect = this.tabsWrapperDesktop?.getBoundingClientRect();
        
            if (containerRect && buttonRect) {
                const offsetLeft = buttonRect.left - containerRect.left - 5;
                const offsetWidth = buttonRect.width;
        
                this.$tabIndicator.style.width = `${offsetWidth}px`;
                this.$tabIndicator.style.transform = `translateX(${offsetLeft}px)`;
            }
        }
    }
}
