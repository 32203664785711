import Swiper from 'swiper';
import { Pagination } from 'swiper/modules';


export default function initMaillagePostsSimilaires() : void {
    const $maillageActuContainer = document.querySelectorAll<HTMLElement>('.maillage-posts-similaires');

    $maillageActuContainer.forEach($container => {
        new MaillagePostsSimilaires($container);
    });
}

class MaillagePostsSimilaires {
    $el: HTMLElement | null | undefined;
    $slider: HTMLElement | null | undefined;
    swiper: Swiper | null = null;
    $pagination: HTMLElement | null | undefined;

    constructor($el: HTMLElement | null) {
        this.$el = $el;
        this.$slider = $el?.querySelector('.wrapper-articles');
        this.$pagination = $el?.querySelector('.maillage-actus-similaires-pagination');

        this.createSwiper();
    }

    /**
     * Créé le swiper
     */
    createSwiper(): void {
        if (this.$slider) {
            this.swiper = new Swiper(this.$slider, {
                modules: [Pagination],
                slidesPerView: 'auto',
                spaceBetween: 30,
                loop: false,
                allowTouchMove: true,
                grabCursor: true,
                pagination: {
                    el: this.$pagination,
                    clickable: true
                },
            });
        }
    }
}
