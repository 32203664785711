/**
 * Ajoute une classe lorsque l'élément devient sticky
 * 
 * @param {HTMLElement} $element
 * @param {string} classToAdd
 * @param {HTMLElement|null} $elementToAddClass Élément sur lequel ajouter la classe (par défaut : $element)
 * 
 * @return {void}
 */
export function toggleClassWhenSticky($element: HTMLElement, classToAdd: string, $elementToAddClass?: HTMLElement) : void {
    // Création d'une div vide juste avant l'élément sticky
    const $observerElement = document.createElement('div');
    $element.parentNode?.insertBefore($observerElement, $element);

    $elementToAddClass = $elementToAddClass || $element;

    // Ajoute la classe sur $elementToAddClass quand $observerElement est en dehors du viewport
    const observer = new IntersectionObserver(entries => {
        if ($elementToAddClass && $observerElement.offsetTop < window.scrollY && $observerElement.getBoundingClientRect().y <= 0) {
            $elementToAddClass?.classList.toggle(classToAdd, entries[0].intersectionRatio === 0);
        } else {
            $elementToAddClass?.classList.remove(classToAdd);
        }
    });
    observer.observe($observerElement);
}