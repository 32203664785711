export default function initTabsVertical() {
    const $blocksTabs = document.querySelectorAll<HTMLElement>('.block-tabs-vertical');
    $blocksTabs.forEach($container => {
        new BlockTabsVertical($container);
    });
}

/**
 * @param {HTMLElement} $el           HTML Element Block nos offres
 */
class BlockTabsVertical {
    $el: HTMLElement | null | undefined;
    $tabsContents: NodeListOf<HTMLElement> | null | undefined;
    $tabsButtons: NodeListOf<HTMLElement> | null | undefined;
    $tabsProgessBar: HTMLElement | null | undefined;


    constructor($el: HTMLElement | null) {
        this.$el = $el;
        this.$tabsContents = $el?.querySelectorAll('[data-tab]');
        this.$tabsButtons = $el?.querySelectorAll('[data-tab-target]');
        this.$tabsProgessBar = $el?.querySelector('.tabs-progress-bar');

        this.init();
    }

    init() {
        this.updateProgressBar();

        this.$tabsButtons.forEach($tabButton => {
            $tabButton.addEventListener('click', () => {
                const target = this.$el?.querySelector('[data-tab='+$tabButton.dataset.tabTarget+']');

                if (target) {
                    this.closeAllTabs();
                    target.classList.add('active');
                    $tabButton.classList.add('active');
                    this.updateProgressBar();
                }
            })
        })
    }

    closeAllTabs() {
        this.$tabsButtons.forEach($tabButton => {
            $tabButton.classList.remove('active');
        })

        this.$tabsContents.forEach($tabContent => {
            $tabContent.classList.remove('active');
        })
    }

    updateProgressBar() {
        if (this.$tabsProgessBar) {
            this.$tabsProgessBar.style.height = this.calculateHeightProgressBar() + 'px';
        }
    }

    calculateHeightProgressBar() {
        let height = 0;
        for (let i = 0; i < this.$tabsButtons.length; i++) {
            const $tabButton = this.$tabsButtons[i];
            if ($tabButton.classList.contains('active')) {
                return height + $tabButton.offsetHeight;
            } else {
                height = height + $tabButton.offsetHeight;
            }
        }
        return height;
    }
    
}
