import Swiper from 'swiper';
import { Navigation, Pagination, EffectFade } from 'swiper/modules';

export default function initCarouselImagesPleineLargeur() : void {
    const $carouselImagesPleineLargeurContainer = document.querySelectorAll<HTMLElement>('.block-carousel-citations');

    $carouselImagesPleineLargeurContainer.forEach($container => {
        new CarouselImagesPleineLargeur($container);
    });
}

class CarouselImagesPleineLargeur {
    $el: HTMLElement | null | undefined;
    $slider: HTMLElement | null | undefined;
    $navigation_prev: HTMLElement | null | undefined;
    $navigation_next: HTMLElement | null | undefined;
    $pagination: HTMLElement | null | undefined;
    swiper: Swiper | null = null;

    constructor($el: HTMLElement | null) {
        this.$el = $el;
        this.$slider = $el?.querySelector('.carousel');
        this.$navigation_prev = $el?.querySelector('.button-prev');
        this.$navigation_next = $el?.querySelector('.button-next');
        this.$pagination = $el?.querySelector('.carousel-citations-pagination');
        this.init();
    }

    init(): void {
        if (this.$slider) {
            this.swiper = new Swiper(this.$slider, {
                modules: [Navigation, EffectFade, Pagination],
                effect: "fade",
                slidesPerView: 1,
                loop: true,
                allowTouchMove: true,
                spaceBetween: 0,
                grabCursor: true,
                navigation: {
                    nextEl: this.$navigation_next,
                    prevEl: this.$navigation_prev,
                },
                pagination: {
                    el: this.$pagination,
                    clickable: true
                },
            });
        }
    }
}
