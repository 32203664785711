export default function initVideo() {
    const $blocksVideo = document.querySelectorAll<HTMLElement>('.block-video');
    $blocksVideo.forEach($container => {
        new BlockVideo($container);
    });
}

/**
 * @param {HTMLElement} $el           HTML Element Block nos offres
 */
class BlockVideo {
    $el: HTMLElement | null | undefined;
    $video: HTMLVideoElement | null | undefined;
    $button: HTMLElement | null | undefined;
    $button_youtube: HTMLElement | null | undefined;
    $thumbnail: HTMLElement | null | undefined;
    
    
    constructor($el: HTMLElement | null) {
        this.$el = $el;
        this.$video = this.$el?.querySelector('.js-video');
        this.$button = this.$el?.querySelector('.js-icon-play');
        this.$button_youtube = this.$el?.querySelector('.js-icon-play-youtube');
        this.$thumbnail = this.$el?.querySelector('.js-video-thumbnail');
 
        this.init();
    }

    init() {
        if (this.$button) {
            this.$button.addEventListener('click', () => {
                this.$thumbnail.classList.add('hidden');
                this.$video.play();
            })
        }

        if (this.$button_youtube) {
            this.$button_youtube.addEventListener('click', () => {
                this.$thumbnail.classList.add('hidden');
                const $youtubeIframeContainer = this.$el?.querySelector<YoutubeIframeHTMLElement>('.js-youtube');
                if ($youtubeIframeContainer && $youtubeIframeContainer.youtubeIframe) {
                    $youtubeIframeContainer.youtubeIframe.player.playVideo();
                }
            })
        }
    }
}
