export default function initInputAutocomplete() : void {
    const $autocompletes = document.querySelectorAll<HTMLInputElement>('.js-autocomplete');

    $autocompletes.forEach($container => {
        new InputAutocomplete($container);
    });
}

class InputAutocomplete {
    $el: HTMLInputElement | null | undefined;

    constructor($el: HTMLInputElement | null) {
        this.$el = $el;

        this.init();
    }

    /**
     * Init input autocomplete
     */
    init(): void {
        const autocomplete = new google.maps.places.Autocomplete(this.$el);

        // Configurez les types d'adresses que vous souhaitez afficher
        autocomplete.setTypes(['geocode']);
    }
}
