import { toggleClassWhenSticky } from "../utils/toggle-class-when-sticky";

export default function initSingleContentSummary() : void {
    const $summaryContainer = document.querySelectorAll<HTMLElement>('.js-wrapper-summary');

    $summaryContainer.forEach($container => {
        new SingleContentSummary($container);
    });
}

class SingleContentSummary {
    $el: HTMLElement | null | undefined;
    $links: NodeListOf<HTMLElement> | null | undefined;
    $summary: HTMLElement | null | undefined;
    activeSectionId: string | null;

    constructor($el: HTMLElement | null) {
        this.$el = $el;
        this.$links = $el?.querySelectorAll('a[href^="#"]');
        this.$summary = $el?.querySelector('.js-summary');
        this.activeSectionId = null;

        this.init();
    }

    /**
     * Init Global
     */
    init(): void {
        // Créez un Intersection Observer avec le callback
        const observer = new IntersectionObserver(this.handleIntersection.bind(this), {
            rootMargin: '-160px', // Marge par défaut
            threshold: 0.5, // Dès que 50% de l'élément d'ancre est visible
        }
        );
        // Ajoutez les éléments d'ancre à l'Intersection Observer
        this.$links.forEach((link) => {
            const target = document.querySelector(link.getAttribute('href'));
            if (target) {
                observer.observe(target);
            }
        });

        // Mobile sticky wrapper summary
        toggleClassWhenSticky(this.$el, 'wrapper-summary-sticky');

    }

    // Créez un callback pour gérer les entrées de l'Intersection Observer
    handleIntersection(entries: IntersectionObserverEntry[]) {
        let closestEntry: IntersectionObserverEntry | null = null;

        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                if (!closestEntry || entry.intersectionRatio > closestEntry.intersectionRatio) {
                    closestEntry = entry;
                }
            }
        });

        if (closestEntry) {
            const targetId = closestEntry.target.getAttribute('id');
            
            if (this.activeSectionId !== targetId) {
                this.$links.forEach((item) => {
                    item.classList.remove('active');
                });

                this.activeSectionId = targetId;

                this.$links.forEach((item) => {
                    if (item.getAttribute('data-target') === targetId) {
                        item.classList.add('active');
                    }
                });
            }
        }
    }
}
