export default function initScrollLogos() {
    const $scrollContainer = document.querySelectorAll<HTMLElement>('.block-liste-logos');
    $scrollContainer.forEach($container => {
        new ScrollLogos($container);
    });
}

class ScrollLogos {
    $el: HTMLElement;
    $logosList: HTMLElement | null;
    $scrollContainer: HTMLElement | null;
    $initScrollContainer: string | null;
    $logos: NodeListOf<HTMLElement> | null;

    constructor($el: HTMLElement) {
        this.$el = $el;
        this.$logosList = $el?.querySelector('.logos-wrapper');
        this.$scrollContainer = $el?.querySelector('.js-scroll-container');
        this.$initScrollContainer = this.$scrollContainer?.innerHTML;

        this.init();
    }

    init() {
        if (this.$scrollContainer.clientWidth >= this.$el.clientWidth - 40) {
            // Calculer le nombre de logos nécessaires pour remplir la largeur de l'écran
            const containerWidth = this.$el.clientWidth;
            const logoWidth = this.$logosList.clientWidth || 0;
            const logosNeeded = Math.ceil(containerWidth / logoWidth) + 1;

            // Cloner le contenu suffisamment de fois
            if (this.$logosList) {
                for (let i = 0; i < logosNeeded; i++) {
                    const copy = this.$logosList.cloneNode(true);
                    this.$scrollContainer.appendChild(copy);
                }
            }

            // Ajouter la class d'animation
            const allLogosList = this.$el?.querySelectorAll('.logos-wrapper');
            allLogosList.forEach($elem => {
                $elem.classList.add('logos-wrapper-slide');
            });
        }
    }
}
