/* eslint-disable @typescript-eslint/no-explicit-any */
declare const scripts: any;
declare const hbspt: any;
/* eslint-enable @typescript-eslint/no-explicit-any */

export default function initDemandeDeDevis() {
    const $demandeDevisContainer = document.querySelectorAll<HTMLElement>('.header-demande-de-devis');
    $demandeDevisContainer.forEach($container => {
        new DemandeDeDevis($container);
    });
}

class DemandeDeDevis {
    $el: HTMLElement;
    $stepsWrapper: NodeListOf<HTMLElement> | undefined | null;
    $stepsButtons: NodeListOf<HTMLElement> | undefined | null;
    $stepsNavButtons: NodeListOf<HTMLElement> | undefined | null;
    $formHubspot: HTMLElement | undefined | null;
    portalId: string | null | undefined;
    formId: string | null | undefined;
    redirection: string | null | undefined;
    options: [] | null | undefined;

    constructor($el: HTMLElement) {
        this.$el = $el;
        this.$stepsWrapper = this.$el.querySelectorAll('[data-step]');
        this.$stepsButtons = this.$el.querySelectorAll('[data-step-target]');
        this.$stepsNavButtons = this.$el.querySelectorAll('.js-stepper-nav-button');
        this.$formHubspot = this.$el.querySelector('.js-hubspot-devis-form');


        this.init();
    }

    init() {
        this.$stepsButtons.forEach($button => {
            const stepID = $button.dataset.stepTarget
            $button.addEventListener('click', () => {
                this.changeStepActive(stepID)
            });
        })
    }

    resetStepActive() {
        this.$stepsWrapper.forEach($wrapper => {
            $wrapper.classList.remove('active');
        })
    }

    resetStepNavActive() {
        this.$stepsNavButtons.forEach($button => {
            $button.classList.remove('active');
        })
    }

    updateStepNav(stepID) {
        this.resetStepNavActive();
        let classActive = 'active';
        this.$stepsNavButtons.forEach($button => {
            if (classActive) {
                $button.classList.add(classActive);
            }
            if ($button.dataset.stepTarget == stepID) {
                classActive = null;
            }
        })
    }

    changeStepActive(stepID) {
        this.resetStepActive();
        this.updateStepNav(stepID);
        const newStepActive = this.$el.querySelector('[data-step=' + stepID + ']');
        newStepActive.classList.add('active');

        if (stepID == 'step3') {
            this.createHubspotForm();
        }
    }

    createHubspotForm() {
        if (this.$formHubspot) {
            this.portalId = this.$formHubspot.dataset.portalId;
            this.formId = this.$formHubspot.dataset.formId;
            this.redirection = this.$formHubspot.dataset.redirection;
        
            hbspt.forms.create(this.generateFormOptions(this.formId));
        }
    }

    /**
     * Création des options Hubspot
     * d'un formulaire à partir de son ID
     * 
     * @param {string}  formId Hubspot form ID
     * 
     * @return {void}
     */
    generateFormOptions(formId: string) {

        const $iframeContainer = document.querySelector('#' + this.$formHubspot.getAttribute('id'));

        const stepInputVilleDepart = this.$el.querySelector('[name=ville-depart]') as HTMLInputElement;
        const valueInputVilleDepart = stepInputVilleDepart.value;
        const stepInputVilleArrivee = this.$el.querySelector('[name=ville-arrivee]') as HTMLInputElement;
        const valueInputVilleArrivee = stepInputVilleArrivee.value;
        const stepInputDate = this.$el.querySelector('[name=date]') as HTMLInputElement;
        const valueInputDate = stepInputDate.value;
        const stepInputRoom = this.$el.querySelector('[name=room]:checked') as HTMLInputElement;
        let valueInputRoom = '';
        if (stepInputRoom) {
            valueInputRoom = stepInputRoom.value;
        }
        const stepInputSurface = this.$el.querySelector('[name=surface]') as HTMLInputElement;
        const valueInputSurface = stepInputSurface.value;
        
        return {
            portalId: this.portalId,
            formId,
            target: '#' + this.$formHubspot.getAttribute('id'),
            onFormReady: $form => {
                const $html = $form.closest('html');
                const linkTag = document.createElement('link');
                linkTag.rel = 'stylesheet';
                linkTag.href = scripts.css_url;
                let $head = null;

                if ($html && (typeof $html === 'object')) {
                    $head = $html[0] ? $html[0].querySelector('head') : $html.querySelector('head');
                }

                if ($head) {
                    $head.appendChild(linkTag);
                }
                
                setTimeout(function() {
                    $iframeContainer.querySelector('iframe').height = $html.scrollHeight;
                    $iframeContainer.querySelector('iframe').style.height = $html.scrollHeight+ 'px';
                }, 1500);

                const $inputVilleDepart = $html.querySelector('[name=ville_de_depart');
                const $inputVilleArrivee = $html.querySelector('[name=ville_d_arrivee');
                const $inputDate = $html.querySelector('[name=periode_souhaitee');
                const $inputNbPieces = $html.querySelector('[name=nombre_de_piece');
                const $inputTailleLogement = $html.querySelector('[name=taille_du_logement');

                $inputVilleDepart.value = valueInputVilleDepart;
                $inputVilleArrivee.value = valueInputVilleArrivee;
                $inputDate.value = valueInputDate;
                $inputNbPieces.value = valueInputRoom;
                $inputTailleLogement.value = valueInputSurface;
            },
            onFormSubmitted: () => {
                if (this.redirection) {
                    document.location.href=this.redirection;
                }
            },
        };
    }
}
